.login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 110px);

    p {
        color: $red;
    }
    h2 {
        color: $white;
    }
}
