.dashboard {
  padding-bottom: 40px;

  .MuiSvgIcon-root.arrow {
    color: $yellow;
    padding: 22px 4px 0 0;
  }

  .container {
    display: flex;
  }

  .MuiInputLabel-animated.MuiInputLabel-animated,
  .MuiInput-underline:after,
  #standard-basic {
    color: white;
    font-size: 0.9rem;
  }

  .duplicate-campaign-fab,
  .delete-campaign-fab {
    color: #acacac;
    margin-right: -6px;
    margin-left: -6px;
  }

  .duplicate-campaign-fab svg,
  .MuiSvgIcon-root.arrow {
    font-size: 1.3rem;
  }

  .delete-campaign-fab svg {
    font-size: 1.5rem;
  }

  .MuiCardHeader-root {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 8px 30px;
    font-weight: 700;
    font-size: 16px;
  }

  .MuiPaper-root {
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    max-width: 700px;
  }

  .MuiGrid-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    > .MuiButtonBase-root {
      padding-top: 14px;
      padding-bottom: 10px;
      border-radius: 5px 5px 0 0;
      margin-top: 1.5%;
    }
  }

  .delete-campaign-fab {
    padding-top: 10px;
    font-size: 15px;
  }

  .MuiCardContent-root {
    padding: 18px 30px;
    height: 140px;
  }

  .campaign-details {
    padding-top: 10px;
    font-size: 15px;
  }

  .MuiCardHeader-content {
    max-width: 270px;
    max-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 4%;
  }

  h1 {
    color: $white;
  }
  .browser-notification {
    position: relative;
  }

  .notification-info {
    flex: 2;
  }

  .notification-preview {
    flex: 1;
    max-width: 100%;
  }

  ul.MuiList-root {
    padding: 0;
  }

  .MuiListItem-root {
    border-top: 1px solid $blue;
    text-decoration: none;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &:hover {
      background-color: $hover-black;
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: white;
  }

  .MuiFormLabel-root {
    color: white;
  }

  .MuiInput-underline:before {
    border-bottom: 1px solid white;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid white;
  }

  .MuiInputBase-input {
    color: white;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid white;
  }

  .MuiTab-textColorSecondary {
    color: white !important;
  }
  .MuiTabs-indicator {
    background-color: white !important;
  }
  .notification-info ul {
    list-style: none;
    text-decoration: none;
    display: inline-block;
    margin: 0;
    vertical-align: -webkit-baseline-middle;
  }

  .not-sent {
    @include dot($yellow);
  }

  .sent {
    @include dot($green);
  }

  .message {
    color: $white;
  }
}

p.MuiFormHelperText-root {
  color: orange;
}
