$dark-blue: #002d48;
$yellow: #ffc200;
$white: #fff;
$light-yellow: #ffd900;
$red: #d94350;
$light-red: #fc4f5d;
$green: #00b5a3;
$light-green: #1ce0cd;
$blue: #0077cc;
$light-blue: #0198e1;
$left-padding-with-offset: calc(17px + 12.5%);
$hover-black: rgba(0, 0, 0, 0.1);

$primary-text-font: Proxima Nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$font_size_regular: 12px;

@mixin dot($color) {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  background-color: $color;
  margin-left: 20px;
}
