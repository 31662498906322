@import "./sass/fonts.scss";
@import "./sass/variables.scss";
@import "./sass/login.scss";
@import "./sass/dashboard.scss";

html,
body {
    margin: 0;
    padding: 0;
}

:disabled {
    cursor: not-allowed !important;
}

body {
    background-color: $dark-blue;
    font-family: Proxima Nova, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url("./assets/background-image.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

main {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: calc(12.5% * 2);
    padding-right: calc(12.5% * 2);

    @media screen and (max-width: 1700px) {
        padding-left: calc(12.5% * 1.5);
        padding-right: calc(12.5% * 1.5);
    }

    @media screen and (max-width: 1450px) {
        padding-left: 12.5%;
        padding-right: 12.5%;
    }

    @media screen and (max-width: 1200px) {
        padding-left: 8%;
        padding-right: 8%;
    }

    h1 {
        color: #fff;
        padding-left: 20px;
        padding-top: 20px;
    }

    /* ### SUB HEADING STYLING ### */

    h3.subheading,
    .push-notification-form {
        padding-left: 20px;
    }

    .yellow-underline {
        margin-left: 20px;
    }

    h3.subheading {
        width: calc(12.5% * 2);
        min-width: 300px;
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        color: #fff;
        padding-top: 6%;

        input {
            -ms-box-sizing: content-box;
            -moz-box-sizing: content-box;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            min-height: 16px;
            border: none;
            border-radius: 0px;
            background-color: transparent;
            color: #fff;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .status-draft {
        color: $red;
    }

    .status-sent {
        color: $green;
    }

    .yellow-underline {
        background-color: $yellow;
        width: 15%;
        min-width: 100px;
        height: 4px;
        margin-top: 20px;
    }
}

/* ### SUB HEADING STYLING ENDS ### */

/* ### FORM FIELD STYLING ### */

.push-notification-form {
    padding-top: 38px;

    fieldset {
        border: none;
        margin: 0;
        padding: 0;
        padding-bottom: 38px;
        display: flex;
        flex-direction: column;

        h3 {
            text-transform: uppercase;
            color: #fff;
            font-size: 18px;
            min-width: 300px;
            margin-block-end: 0px;
        }

        input {
            -ms-box-sizing: content-box;
            -moz-box-sizing: content-box;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            min-width: 300px;
            min-height: 16px;
            border: none;
            border-bottom: solid 1px rgba(255, 255, 255, 1);
            border-radius: 0px;
            background-color: transparent;
            color: #fff;
            font-size: 13px;
            padding: 28px 0px 5px 0px;
            width: calc(12.5% * 2.5);

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: rgb(134, 134, 134);
                font-size: 13px;
            }
        }

        #redirect-link {
            margin-top: 28px;
            resize: none;
            width: calc(12.5% * 2.5);
            min-width: 300px;
            min-height: 64px;
            padding: 0 0 5px 0;
            font-size: 13px;
            background-color: transparent;
            border: none;
            color: #fff;
            border-bottom: solid 1px rgba(255, 255, 255, 1);

            &:focus {
              outline: none;
            }
        }
    }

    .redirect-link {
        svg {
            fill: $white;
            vertical-align: bottom;
            margin: 0 10px;
        }

        label {
            display: none;
        }

        span,
        p {
            color: $yellow;
            font-size: 12px;
        }

        .error {
            color: $red;
        }

        .lock:hover {
          cursor: pointer;
        }
    }

    .metrics {
      .web-icon {
        cursor: pointer;
        color: #fff;
        fill: $white;
        vertical-align: bottom;
        margin: 0 10px;

        .MuiLink-root {
          color: #fff;
        }
      }
    }
}

/* ### FORM FIELD STYLING ENDS ### */

/* ### BROWSER MODEL STYLING ### */

div.browser-notification {
    transition: right ease-in-out 0.6s, opacity ease-in-out 0.4s;
}

div.browser-model {
    position: absolute;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    width: 500px;
    height: 400px;
    right: calc(12.5% * 2);
    z-index: 5;
    top: 240px;

    @media screen and (max-width: 1700px) {
        right: calc(12.5% * 1.5);
    }

    @media screen and (max-width: 1450px) {
        right: 12.5%;
    }

    @media screen and (max-width: 1200px) {
        right: 8%;
    }

    div.browser-pane {
        width: 100%;
        height: 40px;
        background-color: #d9dde2;
    }

    div.browser-notification {
        margin-top: 15px;
        right: 15px;
    }

    @media screen and (max-width: 1700px) {
        right: calc(12.5% * 1.5);
    }

    @media screen and (max-width: 1450px) {
        right: 12.5%;
    }

    @media screen and (max-width: 1200px) {
        right: 8%;
    }

    div.browser-pane {
        width: 100%;
        height: 40px;
        background-color: #d9dde2;
    }

    div.browser-notification {
        margin-top: 15px;
        right: 15px;
    }
}

/* ### BROWSER MODEL STYLING ENDS ### */

/* ### NOTIFICATION MODEL STYLING ### */

div.browser-notification {
  // these values aim to mirror the size of a chrome desktop notification on 1280 x 800 resolution (Mac) screens
    width: 342px;
    max-width: 100%;
    height: 62px;
    background-color: #eff1f2;
    position: absolute;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .browser-notification-title {
        font-size: 12px;
    }

    div.browser-notification-icon {
        width: 34px;
        height: 34px;
        padding-left: 10px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    div.browser-notification-brand-icon {
        width: 46px;
        height: 46px;
        padding-right: 10px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    div.browser-notification-details {
        width: 225px;
        height: 76%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-around;

        h6 {
            margin: 0;
            font-size: 10.5px;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;
            color: #3F3F3F;
        }

        h6.browser-notification-title {
          font-size: 12px;
        }

        h6:last-of-type {
          font-weight: 400;
        }

        a {
            text-decoration: none;
            color: inherit;
        }
    }
}

/* ### NOTIFICATION MODEL STYLING ENDS ### */

/* ### CTA BUTTON STYLING ### */

.cta-button-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: calc(12.5% * 2);
    top: 680px;

    @media screen and (max-width: 1700px) {
        right: calc(12.5% * 1.5);
    }

    @media screen and (max-width: 1450px) {
        right: 12.5%;
    }

    @media screen and (max-width: 1200px) {
        right: 8%;
    }

    .ready-to-send-button {
        margin-left: 20px;
    }
}

button.button {
    padding: 0;
    border: none;
    width: auto;
    min-width: 140px;
    height: 48px;
    line-height: 48px; // should be same as height to keep vertically centered
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }
}

.preview-button {
    background-color: $blue;
    color: #fff;

    &:hover {
        background-color: $light-blue;
    }
}

.ready-to-send-button {
    background-color: $yellow;
    color: $blue;

    &:hover {
        background-color: $light-yellow;
    }
}

.edit-needed-button {
    background-color: $red;
    color: #fff;
    padding-left: 20px !important;
    padding-right: 20px !important;

    &:hover {
        background-color: $light-red;
    }
}

.send-button,
.save-button {
    background-color: $green;
    color: #fff;
    padding-left: 20px !important;
    padding-right: 20px !important;

    &:hover {
        background-color: $light-green;
    }
}

.button:disabled {
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

/* ### CTA BUTTON STYLING ENDS ### */

/* ### CONFIRMATION MODAL STYLING ### */

.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    padding-left: calc(12.5% * 2);
    padding-top: 340px;

    @media screen and (max-width: 1700px) {
        padding-left: calc(12.5% * 1.5);
    }

    @media screen and (max-width: 1450px) {
        padding-left: 12.5%;
    }

    @media screen and (max-width: 1200px) {
        padding-left: 8%;
    }

    h3 {
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
        padding-left: 20px;
        padding-bottom: 20px;
    }

    .edit-needed-button,
    .send-button {
        margin-left: 20px;
    }
}

.modal {
    &.center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.builder-modal {
    &-visible {
        opacity: 1;
        z-index: 4;
        transition: all ease-in-out 0.2s;
    }

    &-hidden {
        opacity: 0;
        z-index: -1;
        transition: all ease-in 0.2s;
    }
}

/* ### CONFIRMATION MODAL STYLING ENDS ### */

/* ## HEADER ## */
.email-token {
    a {
        text-decoration: none;
    }
}

@media (max-width: 499.95px) {
   .makeStyles-logo-6 {
     width: 50px;
   }
}